import React from 'react';
import { View } from 'react-native';
import LottieView from 'lottie-react-native';

const Loading = ({ background = null, ...props }) => {
  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        backgroundColor: background,
        zIndex: 99999,
        justifyContent: 'center',
        alignItems: 'center',
        // opacity: 0.6,
        position: 'absolute',
        bottom: 0,
        top: 0,
      }}
    >
      <View>
        <LottieView
          autoPlay
          loop
          style={{ width: 100, height: 100 }}
          source={require('../../assets/lottie/loading_new.json')}
        />
      </View>
    </View>
  );
};

export default Loading;
